<template>
  <div class="pb-20">
    <div class="grid grid-cols-1 md:grid-cols-2 mb-4">
      <div class="col-span-1 flex items-center flex-wrap">
        <h2 class="text-2xl font-bold mr-3">New Documents</h2>
      </div>
    </div>

    <div class="border border-solid border-t-0 border-blue-200 pt-4">
      <datatable
        :ajax="true"
        :ajaxPagination="true"
        :columns="docs.columns"
        :query="docs.query"
        :loading="docs.loading"
        :url="docs.url"
        :onClick="click"
        sort="created_at"
        order="asc"
        ref="table"
      >
        <template #header>
          <search-form
            v-model="docs.query"
            class="mb-5"
            placeholder="Search for user's name, doc, etc."
            @submit="reloadTable"
          />
        </template>
      </datatable>
    </div>
    <modals-user
      :selected-user="docs.selected"
      ref="userModal"
      @close="userModalClosed"
    />
  </div>
</template>
<script>
export default {
  data() {
    return {
      currentFilter: null,
      docs: this.$options.resource([], {
        url: `${this.$baseurl}/admin/personal/users/documents-event/upload`,
        query: '',
        selected: null,
        columns: [
          {
            name: 'id',
            th: 'User ID',
            render: ({ user }) => user && user?.id
          },
          {
            name: 'name',
            th: 'Name',
            render: ({ user }) => user && `${user?.name} ${user?.last_name}`
          },
          {
            name: 'doc',
            th: 'Document'
          },
          {
            name: 'doc_type',
            th: 'Document Type'
          },
          {
            name: 'uploaded_at',
            th: 'Uploaded At',
            render: doc => this.$moment(doc?.uploaded_at).format('MMM Do YYYY')
          }
        ]
      })
    };
  },
  methods: {
    reloadTable() {
      this.$refs.table.loadAjaxData();
    },
    click({ user }) {
      this.docs.selected = user;
      this.$refs.userModal.open();
    },
    userModalClosed() {
      this.docs.selected = null;
    }
  }
};
</script>
